import React from "react"
import styled from "styled-components"

const VideoBg = () => {
  return (
    <Wrapper>
      <video
        preload="auto"
        id="video-bg-animate"
        autoPlay="autoplay"
        loop="loop"
        playsInline
        // width="1680px"
        // height="1050px"
        muted
        poster="/images/hero/norvina_title_background02.jpg"
      >
        <source
          src="/images/hero/norvina_title_background02.mp4"
          type="video/mp4"
        />
        <img
          src="/images/hero/norvina_title_background02.jpg"
          alt="Introducing Norvina Lilac Palette."
        />
        Your browser does not support the video tag.
      </video>
    </Wrapper>
  )
}

export default VideoBg

const Wrapper = styled.div`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -100px;
  left: -20px;
  z-index: -11;
  overflow: hidden;
  @media (min-width: 1280px) and (max-width: 1366px) {
    /* width: 1120px;
      height: 700px; */
    top: -100px;
    left: -30px;
  }

  @media (max-width: 1279px) {
    display: none;
  }
  video {
    width: 1680px;
    height: 1050px;

    @media (min-width: 1367px) and (max-width: 1440px) {
      width: 1580px;
      height: auto;
    }

    @media (min-width: 1280px) and (max-width: 1366px) {
      width: 1500px;
      height: auto;
    }
  }
`
