import React from "react"
import styled from "styled-components"
import { Caption } from "../styles/TextStyles"
import { useState, useEffect } from "react"

function getWindowDimensions() {
  let defaultHeight
  let defaultWidth

  if (typeof window !== `undefined`) {
    defaultHeight = window.innerHeight
    defaultWidth = window.innerWidth
  }

  return {
    defaultHeight,
    defaultWidth,
  }
}

let defaultHeight
let defaultWidth

if (typeof window !== `undefined`) {
  defaultHeight = window.innerHeight
  defaultWidth = window.innerWidth
}

const useWindowSize = () => {
  const [active, setActive] = useState("")
  const [dimensions, setDimensions] = useState({
    windowHeight: defaultHeight,
    windowWidth: defaultWidth,
  })
  let targetElement
  useEffect(() => {
    setActive(document.activeElement)
    targetElement = document.activeElement.querySelector("body")
    const handler = () =>
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })

    window.addEventListener(`resize`, handler)
    return () => window.removeEventListener(`resize`, handler)
  }, [])

  return dimensions
}

function Game() {
  const [lockGame, setLockGame] = useState("none")
  const [lockOverlay, setLockOverlay] = useState("inline")

  const lockGameScreen = () => {
    setLockGame("inline")
    setLockOverlay("none")
  }

  const [viewSize, setViewSize] = useState(0)

  return (
    <Wrapper>
      <ContentWrapper>
        <GameWrapper
          viewHeight={useWindowSize().windowHeight - 50 + "px"}
          // winHeight={useWindowSize().windowHeight * -1}
        >
          <GameLockBtnOverlay onClick={lockGameScreen} lockBtn={lockOverlay} />
          <GameLockWrapper lock={lockGame}>
            <Img
              src="/images/game/orientationIcon1.svg"
              alt="Turn device to landscape"
              className="icon-device-turn"
            />
            <TextLock>
              To play game, please rotate your device to landscape orientation
            </TextLock>
            <GameLock />
          </GameLockWrapper>

          {/* src="http://www.collectedworlds.net/punk/index.html" */}

          <iframe
            src="/punk/index.html"
            title="Game Window"
            scrolling="no"
            align="middle"
            allowFullScreen={true}
            webkitallowfullscreen="true"
            msallowfullscreen="true"
            mozallowfullscreen="true"
            marginHeight="0"
            marginWidth="0"
            width="100%"
            height="100%"
            maxwidth="1280px"
            maxheight="720px"
            loading="lazy"
            allow="fullscreen *; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </GameWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Game

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 1680px;
  max-height: 768px;
  overflow: hidden;
  grid-area: game;

  @media (max-width: 1200px) and (orientation: landscape) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    min-height: 250px;
    z-index: 11;
    overflow: hidden;
    margin: 0;
    padding: 2.5em 1.5em 2.5em 1.5em;
    padding: 2.5em constant(safe-area-inset-right) 2.5em
      constant(safe-area-inset-left);
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  max-height: 720px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    width: 100vw;
    height: auto;
  }
`

const GameWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 926px) and (min-width: 812px) {
    padding-top: 42.15%; /* 13:6 Aspect Ratio (divide 6 by 13 = 0.4615) */
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 0;
  }
  @media (max-width: 1200px) and (orientation: landscape) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    min-height: 250px;
    /* height: 100vh; */
    z-index: 11;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-overflow-scrolling: touch;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -webkit-tap-highlight-color: transparent;
  }
  /* @media (min-width: 927px) and (max-width: 1024px) and (orientation: landscape) {
    margin: ${props => (props.winHeight * 38) / 100 + "px"} 0;
    top: 50%;
    margin: -28% 0; }*/
`

const GameLockBtnOverlay = styled.div`
  display: none;
  @media (max-width: 1280px) and (orientation: portrait) {
    /* background-color: #000; */
    display: ${props => props.lockBtn};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 15;
  }
  @media (max-width: 1280px) and (orientation: landscape) {
    display: none;
  }
`
const GameLockWrapper = styled.div`
  display: none;
  @media (max-width: 1280px) and (orientation: portrait) {
    display: ${props => props.lock};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1280px) and (orientation: landscape) {
    display: none;
  }
`
const GameLock = styled.div`
  display: none;

  @media (max-width: 1280px) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 12;
    overflow: hidden;
    margin: 0;
    padding: 0;
    opacity: 80%;
  }
`
const TextLock = styled(Caption)`
  display: none;
  @media (max-width: 1280px) and (orientation: portrait) {
    display: inline;
    color: #ffffff;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    text-align: center;
    width: 90%;
    padding: 0 2rem;
  }
`
const Img = styled.img`
  display: none;
  @media (max-width: 1280px) and (orientation: portrait) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 40%;
    opacity: 100%;
    z-index: 13;
    top: 35%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }
`
