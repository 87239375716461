import React from "react"
import styled from "styled-components"
import { useState } from "react"
import { H3, H4, Caption } from "../styles/TextStyles"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Button from "../buttons/Button"

const handleDragStart = e => e.preventDefault()
const responsive = {
  0: { items: 1 },
  568: { items: 2.5 },
  1024: { items: 3.5 },
}

const prizeItems = [
  <div className="slider-card" data-value="1">
    <img src="/images/carousel/bucket_hat.png" onDragStart={handleDragStart} />
    {/* <H4>LIMITED EDITION BUCKET HAT</H4> */}
  </div>,

  <div className="slider-card" data-value="2">
    <img
      src="/images/carousel/black_hoodie_alt.png"
      onDragStart={handleDragStart}
    />
    {/* <H4>LIMITED EDITION BLACK HOODIE</H4> */}
  </div>,

  <div className="slider-card" data-value="3">
    <img
      src="/images/carousel/lilac_hoodie.png"
      onDragStart={handleDragStart}
    />
    {/* <H4>LIMITED EDITION LILAC HOODIE</H4> */}
  </div>,
  <div className="slider-card" data-value="4">
    <img src="/images/carousel/norvina_t.png" onDragStart={handleDragStart} />
    {/* <H4>LIMITED EDITION NORVINA T</H4> */}
  </div>,
  <div className="slider-card" data-value="5">
    <img
      src="/images/carousel/norvina tote.png"
      onDragStart={handleDragStart}
    />
    {/* <H4>LIMITED EDITION LILAC TOTE</H4> */}
  </div>,
]

const productItems = [
  <div className="slider-card" data-value="2">
    <img
      src="/images/carousel/palette.png"
      onDragStart={handleDragStart}
      className="product-img"
    />
    <H4>
      NORVINA PRO PIGMENT <br />
      PALETTE VOL. 5
    </H4>
    <H4 className="price">&#36;60 usd</H4>
    <Button
      className="btn"
      text="Shop Now"
      productlink="https://www.anastasiabeverlyhills.com/norvina-pro-pigment-palette-volume-5/ABH01-00077.html"
    />
  </div>,
  <div className="slider-card" data-value="3">
    <img
      src="/images/carousel/lashes.png"
      onDragStart={handleDragStart}
      className="product-img"
    />
    <H4>
      NORVINA <br />
      FALSE LASHES
    </H4>
    <H4 className="price">&#36;12 usd</H4>
    <Button
      className="btn"
      text="Shop Now"
      productlink="https://www.anastasiabeverlyhills.com/products/eye-makeup/false-lashes/"
    />
  </div>,
  <div className="slider-card" data-value="1">
    <img
      src="/images/carousel/lip_balm.png"
      onDragStart={handleDragStart}
      className="product-img"
    />
    <H4>
      NORVINA LIP BALM <br />- STRAWBERRY
    </H4>
    <H4 className="price">&#36;18 usd</H4>
    <Button
      className="btn"
      text="Shop Now"
      productlink="https://www.anastasiabeverlyhills.com/norvina-lip-balm-strawberry/ABH01-18612.html"
    />
  </div>,

  <div className="slider-card" data-value="3">
    <img
      src="/images/carousel/lip_balm_yel.png"
      onDragStart={handleDragStart}
      className="product-img"
    />
    <H4>
      NORVINA LIP BALM <br />- MANGO
    </H4>
    <H4 className="price">&#36;18 usd</H4>
    <Button
      className="btn"
      text="Shop Now"
      productlink="https://www.anastasiabeverlyhills.com/norvina-lip-balm-mango/ABH01-18613.html"
    />
  </div>,
  <div className="slider-card" data-value="3">
    <img
      src="/images/carousel/lip_balm_coco.png"
      onDragStart={handleDragStart}
      className="product-img"
    />
    <H4>
      NORVINA LIP BALM <br />- COCONUT
    </H4>
    <H4 className="price">&#36;18 usd</H4>
    <Button
      className="btn"
      text="Shop Now"
      productlink="https://www.anastasiabeverlyhills.com/norvina-lip-balm-coconut/ABH01-18611.html"
    />
  </div>,
]

const Carousel = props => {
  const [itemIndex, setItemIndex] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [buttonLOpacity, setButtonLOpacity] = useState(0.5)
  const [buttonROpacity, setButtonROpacity] = useState(1)

  const slidePrev = () => {
    if (itemIndex <= 0) {
      setItemIndex(0)
      setScrollPosition(0)
      setButtonLOpacity(0.5)
      setButtonROpacity(1)
    } else {
      setItemIndex(itemIndex - 1)
      setScrollPosition(scrollPosition - 31)
      setButtonLOpacity(1)
      setButtonROpacity(1)
    }
    if (itemIndex <= 1) {
      setButtonLOpacity(0.5)
      setButtonROpacity(1)
    }
  }
  const slideNext = () => {
    if (itemIndex >= 2) {
      setItemIndex(2)
      setScrollPosition(62)
      setButtonLOpacity(1)
      setButtonROpacity(0.5)
    } else {
      setItemIndex(itemIndex + 1)
      setScrollPosition(scrollPosition + 31)
      setButtonLOpacity(1)
      setButtonROpacity(1)
    }
    if (itemIndex >= 1) {
      setButtonROpacity(0.5)
    }
  }
  const syncItems = e => {
    setItemIndex(e.item)
  }

  return (
    <Wrapper area={props.area} displaytype={props.displaytype}>
      <ContentWrapper>
        <Title>{props.title}</Title>
        <SliderWrapper marginBot={props.newKey === 1 ? "1rem" : "3rem"}>
          <ButtonWrapper className="button-wrap-left" fadeLeft={buttonLOpacity}>
            <button onClick={slidePrev} className="btn-left">
              <i className="arrow left"></i>
            </button>
          </ButtonWrapper>

          <AliceCarousel
            activeIndex={itemIndex}
            startIndex={0}
            // mouseTracking
            touchTracking
            items={props.newKey === 1 ? prizeItems : productItems}
            responsive={responsive}
            mouseTrackingEnabled={true}
            disableButtonsControls
            slideToIndex={itemIndex}
            onSlideChanged={syncItems}
            ssrSilentMode
            stagePadding={0}
          />
          <ButtonWrapper
            className="button-wrap-right"
            fadeRight={buttonROpacity}
          >
            <button onClick={slideNext} className="btn-right">
              <i className="arrow right"></i>
            </button>
          </ButtonWrapper>
        </SliderWrapper>
        <ProgressBarWrapper>
          <ProgressBar position={`${scrollPosition}%`} />
        </ProgressBarWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Carousel

const Wrapper = styled.div`
  width: 100%;
  grid-area: ${props => props.area};
  max-width: 1680px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 0 4rem 0;
  @media (max-width: 768px) {
    /* padding: 0 2.5rem; */
  }

  @media (max-width: 550px) {
    margin: 0 0 0 0;
  }
  @media (max-width: 1200px) and (orientation: landscape) {
    display: ${props => props.displaytype};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  overflow-x: hidden;
`
const Title = styled(H3)`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
  text-align: center;
  overflow: hidden;
  line-height: 150%;

  @media (max-width: 550px) {
    padding: 1rem 88px 0 88px;
    margin-bottom: 0rem;
  }
`

const ButtonWrapper = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
  position: absolute;
  z-index: 10;

  &:first-child {
    left: 0;
    margin-left: 20px;
    opacity: ${props => props.fadeLeft};
  }

  &:last-child {
    right: 0;
    margin-right: 20px;
    opacity: ${props => props.fadeRight};
  }
  @media (max-width: 1024px) {
    display: none;
  }
`

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1680px;

  @media (max-width: 1024px) {
    width: 100%;

    & .alice-carousel__dots {
      display: flex;
      justify-content: center;
      margin-top: -2rem;
      margin-bottom: 2rem;
    }
    & .alice-carousel__dots-item {
      background-color: #c4b3e2;
    }

    .alice-carousel__dots-item.__active {
      background-color: #e616ff;
    }
  }

  @media (min-width: 1280px) {
    & .alice-carousel__dots {
      display: none;
      background-color: #c4b3e2;
    }
  }

  & .alice-carousel {
    width: 100%;
    max-width: 1680px;
  }
  & .alice-carousel__wrapper {
    justify-content: flex-start;
    white-space: nowrap;
    margin-bottom: ${props => props.marginBot};
    /* width: 100%; */

    -webkit-overflow-scrolling: touch;

    @media (max-width: 1024px) {
      /* padding: 0 !important; */
      justify-content: center;
      scroll-behavior: [ auto | smooth ];
      scroll-snap-type: mandatory;
      scroll-snap-points-w: repeat(100vw);
      scroll-snap-type: w mandatory;
      overflow-x: inherit;
    }
    @media (max-width: 550px) {
      margin-top: 0;

      /* padding: 0 !important; */
    }
  }

  & .alice-carousel__stage {
    overflow: visible;
    white-space: nowrap;
  }

  & .slider-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    height: auto;
    overflow-y: hidden;
  }
  & .desc {
    margin: 15px 0;
  }
  & .price {
    margin-bottom: 10px;
  }
  & .btn {
    display: flex;
    justify-content: center;
    overflow: visible;
    width: 100%;
    margin-top: 2rem;
  }
  img {
    height: auto;
    width: 80%;
  }
  & .product-image {
    height: auto;
    width: 33%;
  }
  button {
    //Arrow buttons for carousel
    border-radius: 100px;
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: solid 2px #c4b3e2;
    cursor: pointer;
  }
  & .btn-left {
  }
  & .btn-right {
    opacity: ${props => props.fadeRight};
  }
  & .arrow {
    border: solid #c4b3e2;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }
  & .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
`
const ProgressBarWrapper = styled.div`
  position: relative;
  height: 4px;
  background-color: #c4b3e2;
  width: 75%;

  @media (max-width: 1280px) {
    display: none;
  }
`
const ProgressBar = styled.div`
  position: absolute;
  height: 4px;
  background-color: #e616ff;

  width: 40%;
  left: ${props => props.position};
  transition: 0.5s ease;
`
