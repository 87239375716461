import React from "react"
import styled from "styled-components"
import { LegalText } from "../styles/TextStyles"

const GameLegal = props => {
  return (
    <Wrapper type={props.displaytype}>
      <Legal>
        By playing, you accept the{" "}
        <a
          href="https://www.anastasiabeverlyhills.com/terms.html"
          target="_blank"
        >
          Terms and Conditions
        </a>
        ,{" "}
        <a
          href="https://www.anastasiabeverlyhills.com/privacy-policy.html"
          target="_blank"
        >
          Privacy Policy
        </a>
        , and{" "}
        <a href="/rules" target="_blank">
          Official Rules
        </a>
        . Must be US resident, 18 or older. Ends 11:59:59 p.m. PT on 07/04/2021.
        Winners selected at random, not based on game results. Additional terms
        apply. See{" "}
        <a href="/rules/" target="_blank">
          Official Rules
        </a>{" "}
        for details.
      </Legal>
    </Wrapper>
  )
}

export default GameLegal

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1680px
  overflow: hidden;
  grid-area: gamelegal; 

    @media (max-width: 1200px) and (orientation: landscape) {
      display: ${props => props.displaytype};
  }  
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1680px;
  width: 80%;
  height: auto;
  overflow: hidden;
`

const Legal = styled(LegalText)`
  text-align: center;
  margin: 2rem 0;
  width: 50%;

  a {
    color: #c4b3e2;
  }
  @media (max-width: 1024px) {
    margin: 0.5rem 0;
    padding: 0 90px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin: 0.5rem 6rem;
    padding: 0px;
    width: 100%;
    font-size: 0.625rem;
    a {
      color: #c4b3e2;
      font-size: 0.625rem;
    }
  }
`
