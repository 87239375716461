import React from "react"
import styled from "styled-components"
// import { BodyMain } from "../styles/TextStyles"

const linkStyle = {
  textDecoration: "none",
  color: "#c4b3e2",
}

const Button = ({ text, productlink }) => {
  return (
    <Wrapper>
      <a href={productlink} target="_blank">
        <SVGWrapper
          viewBox="0 0 60 30"
          preserveAspectRatio="none"
          type="image/svg+xml"
        >
          <polygon
            className="shape"
            points="15,10  55, 10  45, 20  5, 20"
            x="0"
            y="0"
            width="50%"
            height="50%"
          />
          <text
            fontSize="0.25rem"
            fill="#e616ff"
            className="cta"
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
          >
            {text}
          </text>
        </SVGWrapper>
      </a>
      <LineAnimation delay="0.8s" />
      <LineAnimation delay="0.6s" />
      <LineAnimation delay="0.4s" />
      <LineAnimation delay="0.1s" />
    </Wrapper>
  )
}

export default Button
const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0 1rem 0 0;
  width: 240px;
  height: 48px;
  overflow: visible;
`

const SVGWrapper = styled.svg`
  width: 100%;
  height: 250%;
  /* display: flex;
  justify-content: center;
  align-items: center; */

  & .shape {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 0.5px;
    fill: transparent;
    stroke: #e616ff;
    border: solid 1px #e616ff;
    animation-name: btn-animation;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    opacity: 1;
  }
  & .cta {
    color: #e616ff;
    font-family: "Audiowide", cursive;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
  }

  @keyframes btn-animation {
    0% {
      stroke-dasharray: 140 540;
      stroke-dashoffset: 474;
      stroke-width: 0.5px;
    }
    20% {
      stroke-width: 0.5px;
      stroke-dashoffset: 0;
      stroke-dasharray: 0 760;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    80% {
      stroke-width: 0.5px;
      stroke-dashoffset: 0;
      stroke-dasharray: 760 0;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    100% {
      stroke-dasharray: 140 540;
      stroke-dashoffset: -474;
      stroke-width: 0.5px;
    }
  }
`
const LineAnimation = styled.div`
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  width: 3px;
  background-color: #e616ff;
  height: 55px;
  overflow: visible;
  margin: 0 3px -5px -12px;
  transform: rotate(45deg);
  animation: lineAnimate 8s linear infinite;
  animation-delay: ${props => props.delay};
  opacity: 0;

  @media (max-width: 480px) {
    height: 55px;
    width: 2px;
    margin: 0 3px -5px -12px;
  }

  @keyframes lineAnimate {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
`

// const InnerWrapper = styled.svg`
//   /* cursor: pointer;
//   font-family: "Audiowide", cursive;
//   width: 95%;
//   height: 95%;
//   background: #000a25;
//   display: flex;
//   justify-content: center;
//   align-items: center; */
//   /* clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%); */
// `

// const Text = styled(BodyMain)`
//   position: absolute;
//   color: #e616ff;
//   font-family: "Audiowide", cursive;
//   font-size: 1rem;
//   text-transform: uppercase;
//   cursor: pointer;
// `
