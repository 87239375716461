import React from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/layout/seo"
import Game from "../components/sections/Game"
import Body from "../components/sections/Body"
import Nav from "../components/sections/Nav"
import Footer from "../components/sections/Footer"
import Carousel from "../components/sections/Carousel"
import Ticker from "../components/Ticker/Ticker"
import GameLegal from "../components/sections/GameLegal"

function GamePage() {
  return (
    <Layout>
      <Seo title="Home" />
      <Nav displaytype="none" />
      <Ticker area="ticker1" displaytype="none" />
      <Game />
      <GameLegal displaytype="none" />
      <Carousel
        newKey={1}
        area={"slider"}
        title={"Play now for a chance to win exclusive prizes"}
        displaytype="none"
      />
      <Ticker area="ticker2" displaytype="none" />
      <Body boo={true} displaytype="none" />
      <Carousel
        newKey={2}
        area={"shop"}
        title={"Shop the Collection"}
        btn={true}
        displaytype="none"
      />
      <Footer show={true} displaytype="none" />
    </Layout>
  )
}
export default GamePage
