import React from "react"
import styled from "styled-components"
import { H1 } from "../styles/TextStyles"

const Ticker = props => {
  return (
    <Wrapper area={props.area} displaytype={props.displaytype}>
      <Image src="/images/ticker/tickerText.svg" />
    </Wrapper>
  )
}

export default Ticker

const Wrapper = styled.div`
  grid-area: ${props => props.area};
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1680px;
  margin: 0 0 2rem 0;
  overflow-x: hidden;

  @media (max-width: 1023px) {
    width: 120%;
    max-width: 1023px;
  }

  @media (max-width: 550px) {
    max-width: 550px;
    margin: -1rem; 0 -1rem 0;
  }
  @media (max-width: 1024px) and (orientation: landscape) {
    display: none;
  } 
`
const Image = styled.img`
  margin: 3rem 0 0 0;
  width: 100%;
  height: auto;
  overflow: hidden;

  @media (max-width: 550px) {
    margin: 3rem 0 2rem 0;
  }
`
const Text = styled(H1)`
  display: inline-block;
  font-family: "Audiowide";
  text-align: left;
  font-weight: bolder;
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
  font-style: italic;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e616ff;
  white-space: nowrap;
  /* padding-left: 100%; */
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  padding-right: 95.5%;

  @media (max-width: 1440px) {
  }
  @media (max-width: 1023px) {
    font-size: 1.66rem;
    overflow-x: hidden;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    overflow-x: hidden;
    padding-right: 90%;
  }
`
