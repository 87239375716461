import React from "react"
import styled from "styled-components"
import Button from "../buttons/Button"
import { H1, BodyMain } from "../styles/TextStyles"
import { Picture } from "react-responsive-picture"
import VideoBg from "../videoBackground/VideoBg"

function Body(props) {
  return (
    <Wrapper displaytype={props.displaytype}>
      <PictureWrapper>
        <Picture
          className="image-wraps"
          sources={[
            {
              srcSet: "/images/hero/hero_mobile.jpg,",
              media: "(max-width: 768px)",
            },
            {
              srcSet: "/images/hero/heroDesktop.jpg",
            },
            {
              srcSet: "/images/hero/heroDesktop.webp",
              type: "/image/webp",
            },
          ]}
        />
      </PictureWrapper>
      <TextWrapper>
        <TitleText>
          introducing — <br />
          norvina pro <br />
          pigment palette vol. 5
        </TitleText>
        <Description>
          STRAIGHT FROM THE FUTURE, VOLUME 5 OF NORVINA’S ICONIC SERIES IS HERE.
        </Description>
        {props.boo === true ? (
          <ButtonWrapper>
            <ButtonOneWrapper
              text="Shop Now"
              productlink="https://www.anastasiabeverlyhills.com/norvina-pro-pigment-palette-volume-5/ABH01-00077.html"
            />
          </ButtonWrapper>
        ) : (
          ""
        )}
      </TextWrapper>
      <LilacWrapper>
        <Picture
          className="lilac-wrap"
          sources={[
            {
              srcSet: "/images/hero/lilac_palette_mobile.png",
              media: "(max-width: 1024px)",
            },
            {
              srcSet: "/images/hero/lilac_palette_dk.png",
            },
            {
              srcSet: "/images/hero/lilac_palette_dk.webp",
              type: "/image/webp",
            },
          ]}
        />
      </LilacWrapper>
      <VideoBg className="video-bg" />
    </Wrapper>
  )
}

export default Body

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  grid-area: body;
  display: grid;
  grid-template-columns: repeat() (12, 1fr);
  grid-template-rows: 1, 1fr;
  gap: 48px;
  grid-template-areas: "image image image image text text text text lilac lilac lilac lilac";
  width: 100%;
  max-width: 1680px;
  height: 100%;
  overflow: hidden;
  margin-top: 4rem;

  @media (max-width: 1200px) and (orientation: landscape) {
    display: ${props => props.displaytype};
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    overflow: hidden;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, auto);
    max-width: 1280px;
    gap: 24px;
    grid-template-areas:
      "image image image text text text"
      "image image image lilac lilac lilac";
  }
  @media (max-width: 1023px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, auto);
    max-width: 1024px;
    gap: 24px;
    height: auto;
    grid-template-areas:
      "image image image image image image"
      " text text text text text text"
      "lilac lilac lilac lilac lilac lilac";
  }
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    height: auto;
    margin-bottom: 2rem;
    gap: 0;
    grid-template-areas:
      "image"
      "text"
      "lilac";
  }
`
const PictureWrapper = styled.div`
  grid-area: image;
  max-width: 426px;
  max-height: 712.5px;
  width: 110%;
  height: auto;
  margin-left: 24px;
  overflow: hidden;

  & .image-wraps {
    width: 100%;
    height: auto;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    width: 90%;
    height: auto;
  }
  @media (max-width: 1023px) {
    width: 100%;
    margin-left: 0;
    height: auto;
    justify-self: center;
    align-self: end;
    max-width: 602px;
  }
  @media (max-width: 550px) {
    display: flex;
    justify-content: center;
    width: 60%;
    height: auto;
    margin-left: 0;
    justify-self: center;
    align-self: center;
  }
`

const TextWrapper = styled.div`
  overflow-y: hidden;
  grid-area: text;
  margin-top: 6rem;
  width: 180%;
  max-width: 800px;
  display: grid;
  grid-template-rows: 3, auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "title"
    "body"
    "buttons";

  @media (min-width: 1180px) and (max-width: 1280px) {
    overflow: hidden;
    margin-top: 6rem;
    margin-left: -40%;
  }
  @media (min-width: 1024px) and (max-width: 1179px) {
    overflow: hidden;
    margin-top: 6rem;
    margin-left: -35%;
    grid-template-rows: 3, 1fr;
  }
  @media (max-width: 1023px) {
    overflow: hidden;
    margin-top: 3rem;
    margin-left: 0;
    justify-self: center;
    align-self: center;
    gap: 48px;
  }
  @media (max-width: 480px) {
    width: 90%;
    margin-top: 3rem;
    margin-left: 0;
    justify-self: center;
    align-self: center;
    gap: 24px;
  }
`
const Title = styled.div`
  height: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`
const TitleText = styled(H1)`
  display: none;
  cursor: default;
  grid-area: title;
  font-family: "Audiowide";
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #e616ff;
  width: 100%;
  line-height: 90%;
  /* text-shadow: 1px 1px 2px transparent, 0 0 10px transparent, 0 0 10px #e616ff; */
  overflow-y: hidden;
  margin-left: 10px;

  @media (min-width: 1024px) and (max-width: 1279px) {
    margin-left: 0;
    display: inline;
    width: 85%;
    & .title-wraps {
      width: 80%;
    }
  }

  @media (max-width: 1023px) {
    display: inline;
    -webkit-text-stroke-width: 2px;
    width: 90%;
    margin-left: 0;
    justify-self: center;
    align-self: center;
    text-align: center;
    overflow-y: hidden;
    margin-left: 0;

    & .title-wraps {
      width: 80%;
    }
  }
  @media (max-width: 480px) {
    -webkit-text-stroke-width: 1px;
    overflow-y: hidden;
    width: 80%;
    margin-left: 0;
  }
`

const Description = styled(BodyMain)`
  grid-area: body;
  color: #c4b3e2;
  max-width: 480px;
  width: 100%;
  text-transform: uppercase;
  margin-left: 5.1rem;
  margin-top: 12rem;

  @media (min-width: 1367px) and (max-width: 1440px) {
    margin-top: 10rem;
    margin-left: 4.1rem;
  }

  @media (min-width: 1281px) and (max-width: 1366px) {
    margin-top: 7rem;
    margin-left: 3.3rem;
  }
  @media (min-width: 1280px) and (max-width: 1280px) {
    margin-top: 16rem;
    margin-left: 105px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    margin-top: 3rem;
    margin-left: 140px;
    width: 100%;
  }
  @media (max-width: 1023px) {
    margin-left: 0;
    justify-self: center;
    align-self: center;
    text-align: center;
    margin-top: 0;
    width: 80%;
  }
  @media (max-width: 550px) {
    width: 90%;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0 3rem;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
  }
`

const ButtonWrapper = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: space-between;
  max-width: 1024px;
  margin-left: 4.3rem;
  width: 100%;
  overflow: visible;
  margin-top: -4rem;

  @media (min-width: 1367px) and (max-width: 1440px) {
    margin-left: 3rem;
    margin-top: -5rem;
  }

  @media (min-width: 1281px) and (max-width: 1366px) {
    margin-left: 30px;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    margin-top: 3rem;
    margin-left: 2.4rem;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    width: 100%;
    margin-top: 3rem;
    width: 60%;
    justify-content: space-between;
    margin-left: 7.4rem;
  }
  @media (max-width: 1023px) {
    margin-left: 0;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  @media (max-width: 550px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
`
const ButtonOneWrapper = styled(Button)``
const ButtonTwoWrapper = styled(Button)``

const LilacWrapper = styled.div`
  padding: 0;
  grid-area: lilac;
  width: 100%;
  height: auto;
  justify-self: end;
  align-self: flex-end;
  z-index: -1;
  animation: float 10s ease-in-out alternate infinite;

  @keyframes float {
    from {
      transform: rotate(0deg) translateX(5px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(5px) rotate(-360deg);
    }
  }

  & .lilac-wrap {
    width: 80%;
    height: auto;
    z-index: -1;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    grid-area: text;
    left: 75vw;
    display: flex;
    justify-content: flex-end;
    justify-self: end;
    width: 70%;
    z-index: -1;
    margin-bottom: -14rem;
  }
  @media (max-width: 1023px) {
    z-index: -1;
    display: none;
    justify-self: center;
    align-self: center;
    width: 50%;

    & .lilac-wrap {
      width: 50%;
      height: auto;
    }
  }
`
